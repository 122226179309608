exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blood-and-bone-marrow-transplantation-js": () => import("./../../../src/pages/blood-and-bone-marrow-transplantation.js" /* webpackChunkName: "component---src-pages-blood-and-bone-marrow-transplantation-js" */),
  "component---src-pages-booking-booking-by-doctor-js": () => import("./../../../src/pages/booking/booking-by-doctor.js" /* webpackChunkName: "component---src-pages-booking-booking-by-doctor-js" */),
  "component---src-pages-booking-index-js": () => import("./../../../src/pages/booking/index.js" /* webpackChunkName: "component---src-pages-booking-index-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-clinicb-js": () => import("./../../../src/pages/clinicb.js" /* webpackChunkName: "component---src-pages-clinicb-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-from-our-medical-experts-js": () => import("./../../../src/pages/from-our-medical-experts.js" /* webpackChunkName: "component---src-pages-from-our-medical-experts-js" */),
  "component---src-pages-health-information-js": () => import("./../../../src/pages/health-information.js" /* webpackChunkName: "component---src-pages-health-information-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-patients-[slug]-js": () => import("./../../../src/pages/international-patients/[slug].js" /* webpackChunkName: "component---src-pages-international-patients-[slug]-js" */),
  "component---src-pages-international-patients-index-js": () => import("./../../../src/pages/international-patients/index.js" /* webpackChunkName: "component---src-pages-international-patients-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-our-doctors-js": () => import("./../../../src/pages/our-doctors.js" /* webpackChunkName: "component---src-pages-our-doctors-js" */),
  "component---src-pages-our-facilities-js": () => import("./../../../src/pages/our-facilities.js" /* webpackChunkName: "component---src-pages-our-facilities-js" */),
  "component---src-pages-our-speciality-js": () => import("./../../../src/pages/our-speciality.js" /* webpackChunkName: "component---src-pages-our-speciality-js" */),
  "component---src-pages-patient-information-going-home-js": () => import("./../../../src/pages/patient-information/going-home.js" /* webpackChunkName: "component---src-pages-patient-information-going-home-js" */),
  "component---src-pages-patient-information-js": () => import("./../../../src/pages/patient-information.js" /* webpackChunkName: "component---src-pages-patient-information-js" */),
  "component---src-pages-patient-information-plan-your-visit-js": () => import("./../../../src/pages/patient-information/plan-your-visit.js" /* webpackChunkName: "component---src-pages-patient-information-plan-your-visit-js" */),
  "component---src-pages-patient-information-your-hospital-stay-js": () => import("./../../../src/pages/patient-information/your-hospital-stay.js" /* webpackChunkName: "component---src-pages-patient-information-your-hospital-stay-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-second-opinion-for-breast-cancer-js": () => import("./../../../src/pages/second-opinion-for-breast-cancer.js" /* webpackChunkName: "component---src-pages-second-opinion-for-breast-cancer-js" */),
  "component---src-pages-second-opinion-js": () => import("./../../../src/pages/second-opinion.js" /* webpackChunkName: "component---src-pages-second-opinion-js" */),
  "component---src-pages-social-media-policy-js": () => import("./../../../src/pages/social-media-policy.js" /* webpackChunkName: "component---src-pages-social-media-policy-js" */),
  "component---src-pages-swasam-js": () => import("./../../../src/pages/swasam.js" /* webpackChunkName: "component---src-pages-swasam-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-treatment-enquiry-js": () => import("./../../../src/pages/treatment-enquiry.js" /* webpackChunkName: "component---src-pages-treatment-enquiry-js" */),
  "component---src-pages-upcoming-event-js": () => import("./../../../src/pages/upcoming-event.js" /* webpackChunkName: "component---src-pages-upcoming-event-js" */),
  "component---src-pages-video-testimonial-js": () => import("./../../../src/pages/video-testimonial.js" /* webpackChunkName: "component---src-pages-video-testimonial-js" */),
  "component---src-pages-visitors-js": () => import("./../../../src/pages/visitors.js" /* webpackChunkName: "component---src-pages-visitors-js" */),
  "component---src-pages-written-testimonial-js": () => import("./../../../src/pages/written-testimonial.js" /* webpackChunkName: "component---src-pages-written-testimonial-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/Templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-book-by-doctor-js": () => import("./../../../src/Templates/book-by-doctor.js" /* webpackChunkName: "component---src-templates-book-by-doctor-js" */),
  "component---src-templates-cancers-js": () => import("./../../../src/Templates/cancers.js" /* webpackChunkName: "component---src-templates-cancers-js" */),
  "component---src-templates-doctor-js": () => import("./../../../src/Templates/doctor.js" /* webpackChunkName: "component---src-templates-doctor-js" */),
  "component---src-templates-event-js": () => import("./../../../src/Templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-healthcaretv-js": () => import("./../../../src/Templates/healthcaretv.js" /* webpackChunkName: "component---src-templates-healthcaretv-js" */),
  "component---src-templates-medias-js": () => import("./../../../src/Templates/medias.js" /* webpackChunkName: "component---src-templates-medias-js" */),
  "component---src-templates-medical-oncology-js": () => import("./../../../src/Templates/medical_oncology.js" /* webpackChunkName: "component---src-templates-medical-oncology-js" */),
  "component---src-templates-speciality-js": () => import("./../../../src/Templates/speciality.js" /* webpackChunkName: "component---src-templates-speciality-js" */),
  "component---src-templates-treatment-js": () => import("./../../../src/Templates/treatment.js" /* webpackChunkName: "component---src-templates-treatment-js" */),
  "component---src-templates-video-testimonial-js": () => import("./../../../src/Templates/video_testimonial.js" /* webpackChunkName: "component---src-templates-video-testimonial-js" */)
}

